import story from "../assets/data/story.json";
import moment from "moment";
import "moment/locale/nl";
import "../story.css";
import { useNavigate } from "react-router-dom";

moment.locale("nl");

function Story() {
  const navigate = useNavigate();

  return (
    <main id="story">
      <header>
        <div className="container">
          <h2 className="intro-text">Het volledige verhaal.</h2>
          <h3>{story[0].result.text}</h3>
          <h4>
            Dordtse Cultuurnacht
            <br />
            {moment(parseInt(story[5].timestamp.$numberLong)).format(
              "D MMMM YYYY"
            )}{" "}
            – {moment(parseInt(story[5].timestamp.$numberLong)).fromNow()}
          </h4>
        </div>
      </header>
      <section id="story">
        <div className="container">
          {story.slice(1, story.length).map((story, index) => (
            <div key={index} className="story-container">
              <p className="story__word">{story.word}</p>
              <p className="story__result">{story.result.text}</p>
            </div>
          ))}
        </div>
      </section>
      <section id="cta" className="cta-section">
        <div className="container cta__container">
          <h2 className="cta-text">Nieuwsgierig naar meer?</h2>
          <button className="btn-primary" onClick={() => navigate("/")}>
            Lees meer over Cloud Odyssee
          </button>
        </div>
      </section>
    </main>
  );
}

export default Story;
