import story from "../assets/data/story.json";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  return (
    <main id="home">
      <header>
        <div className="container">
          <h2 className="intro-text">
            Stel je een verhaal voor waarin fragmenten uit onze zielen
            samenleven. Een verhaal dat verandert. Een verhaal waarin onze
            herinneringen een fundament vormen voor onze toekomst.
          </h2>
        </div>
      </header>
      <section id="about">
        <div className="container">
          <h2>Een virtueel hiernamaals</h2>
          <p>
            Cloud Odyssee is een levend letterkundig werk geïnitieerd,
            vormgegeven en uitgevoerd door een{" "}
            <a href="/#gpt-3">intelligent algoritme</a>. Het werk is een
            universum dat bestaat uit algoritmische interpretaties van de
            sentimentele weerklank uit zielsfragmenten van menselijke
            deelnemers. Deze fragmenten vormen zich in totaliteit tot een
            evolutionair verhaal – geschreven door een computer – dat constant
            in beweging zal blijven.
          </p>
          <p>
            De tentoongestelde installatie is een portaal dat de wegen vrij
            maakt voor de mens om in veranderlijke vorm voort te blijven bestaan
            op een binair systeem in de cloud.
          </p>
          <p>
            Om deel uit te maken van de Cloud Odyssee scan je de QR code die
            wordt weergegeven in de installatie en geef je een woord uit je
            herinneringen weg aan het algoritme. Het algoritme doet vervolgens
            een sentimentele beproeving en zet je herinnering om in literair
            materiaal: je zielsfragment. Door dit te doen maakt de deelnemer
            deel uit van een collectief poëtisch bewustzijn dat zichzelf
            voortdurend uitbreidt. De Cloud Odyssee is een eeuwigdurend verhaal,
            geschreven door ons allemaal.
          </p>
          <a className="btn btn-secondary" href={"/research/cloud-space.pdf"}>
            Lees het volledige onderzoek
          </a>
        </div>
      </section>
      <section id="cta" className="cta-section">
        <div className="container cta__container">
          <h2 className="cta-text">Nieuwsgierig naar het verhaal?</h2>
          <button className="btn-primary" onClick={() => navigate("/story")}>
            Lees de Cloud Odyssee
          </button>
          <h3 className="cta__story-preview">
            {story.slice(0, 2).map((story, index) => (
              <span key={index}>{story.result.text}</span>
            ))}
          </h3>
        </div>
      </section>
      <section id="gpt-3">
        <div className="container">
          <h2>Het GPT-3 algoritme</h2>
          <p>
            Elke zin, elk woord, elke letter die je in de Cloud Odyssee leest is
            geschreven door een algoritme genaamd GPT-3. Het algoritme van{" "}
            <a href="https://openai.com/">OpenAI</a> is een digitaal brein dat
            aan de hand van meer dan honderdduizend literaire parameters heeft
            geleerd om menselijke tekst te begrijpen en ter plekke nieuwe tekst
            te genereren.
          </p>
          <p>
            Wil je experimenteren met dit algoritme, en ontdekken hoe dit werkt?
            Dit kan op{" "}
            <a href="https://beta.openai.com/examples">
              de playground omgeving
            </a>{" "}
            van OpenAI.
          </p>
        </div>
      </section>
      <section id="artist">
        <div className="container">
          <h2>Over de maker</h2>
          <p>
            Jascha Huisman is gefascineerd door de absurditeit van de dingen om
            ons heen en denkt graag na over de metafysische betekenis van mens
            zijn. Hij is er altijd van uitgegaan dat de mens een morele plicht
            heeft om de wereld te verbeteren. Maar tegelijkertijd gelooft hij
            ook dat er niets van betekenis is na het sterven. Die twee dingen
            lijken zo onoverbrugbaar, totdat Jascha nadacht over dat het
            misschien noodzakelijk is dat we als onderdeel van onze kunstmatige
            evolutie zelf een hiernamaals zouden moeten scheppen dat betekenis
            kan geven aan wat er na de dood gebeurt.
          </p>
          <p>
            Met deze gedachte als uitgangspunt probeert hij de verschillende
            visies van de mens, die zijn gebaseerd op religie, filosofie en
            wetenschappelijke kennis, samen te brengen tot een speculatief
            ontwerp dat antwoord dient te geven op de ontwerpvraag: “Hoe leent
            kunstmatige intelligentie zich om een kunstmatig evolutieproces tot
            stand te brengen dat dient als een virtueel hiernamaals?”
          </p>
          <a className="btn btn-secondary" href="https://www.jaschahuisman.nl">
            Lees over Jascha Huisman
          </a>
        </div>
      </section>
    </main>
  );
}

export default Home;
