import "./index.css";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const menuItems = [
  { name: "Het verhaal", href: "/story" },
  { name: "Over", href: "/#about" },
  { name: "Algoritme", href: "/#gpt-3" },
  { name: "Jascha Huisman", href: "/#artist" },
];

function Navigation() {
  return (
    <nav className="navbar">
      <div className="container navbar__container">
        <div className="nav-title">
          <Link to="/">
            <span>Cloud Odyssee</span>
          </Link>
        </div>
        <ul className="menu">
          {menuItems.map((menuItem, index) => (
            <li key={index} className="menu__item">
              <HashLink to={menuItem.href}>{menuItem.name}</HashLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default Navigation;
